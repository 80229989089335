import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import moment from "moment";

import { API } from "../../../utils/axios/index";
import {
    requestComplete,
    requestStart,
    requestFail,
    toastError,
    toastMessage,
    setNotifications,
    notificationsFetchStart,
    notificationsFetchStop,
    setNewNotificationsCount,
    requestCompStart,
    requestCompFail,
    requestCompComplete,
    toastSuccessMessage,
    disableLoader,
} from "../global/global";
import {
    filterAndMapColumnChannelWise,
    getMaterialFetchData,
} from "../../../components/views/euPricePlanning/euHelper";
import { separatorOptions } from "../../../components/views/euPricePlanning/EUPricePlanningFilterAttributesMaterials-New";
import { tableSettingTabIds } from "../../../components/views/euPricePlanning/TableSettings";
import { attributeOptions } from "../../../components/views/euPricePlanning/EUPricePlanningTableHelpers";
import {
    attributeColumnDefMap,
    tableAttributes,
    tableAttributesOutlet,
} from "../../../components/views/euPricePlanning/EUPriceListLevelPrices";
import {
    hierarchyOptions,
    metricUnitsOptions,
    seasonOptions,
    timePeriodOptions,
    typeOfUnitOptions,
    levelOfUnitOptions,
} from "../../../components/views/euPricePlanning/rollupsComponents/utils";

import { HISTORICAL_DATA } from "../../../constants/Constants";

const defaultSelectedBasePricingAttributes = [
    "productImage",
    "approvalStatus",
    "seasonCode",
    "specialAttribute",
    "seasonName",
    "priceRule",
    "is_price_rule_applied",
    "planningBrand",
    "planningSubBrand",
    "brandGrp",
    "merchDiv",
    "merchOrg",
    "gender",
    "brand",
    "merchSeg",
    "merchSizeGrp",
    "merchCat",
    "color_description",
    "boardNumber",
    "shortBoard",
    "sapStatus",
    "gfePrePack",
    "legacyMatNum",
    "rtlPriceEffectiveDate",
    "whslPriceEffectiveDate",
    "gfeMatStatus",
    "dtcItpFlag",
    "whslItpFlag",
    "regStdCost",
    "regTfgMan",
    "costOrigin",
    "costUsed",
    "factoryCostMan",
    "usMsrp",
    "usWhsl",
    "euStdMsrpMercOverride",
    "euStdWhslMercOverride",
    "dtcProjUnitsMercOverride",
    "whslProjUnitsMercOverride",
    "salesDtcMercOverride",
    "salesWhslMercOverride",
    "marginDtcMercOverride",
    "marginWhslMercOverride",
    "marginPercDtcMercOverride",
    "marginPercWhslMercOverride",
    "parentMaterial",
    "parentSeason",
    "discount",
    "storePrice",
    "typeOfPE",
    "priceEstablishment",
    "discountTable",
    "similarMaterial",
    "smImage",
    "smSeason",
    "smMarket",
    "smChannel",
    "pfsWasMaterial",
    "pfsWasImage",
    "pfsWasSeason",
    "pfsWasMarket",
    "pfsWasIndicated",
    "styleNum",
];

const defaultSelectedGlobalSearchAttributes = [
    "productImage",
    "seasonCode",
    "usMsrp",
    "usWhsl",
    "euStdMsrpMercOverride",
    "discount",
    "euStdWhslMercOverride",
    "sapStatus",
    "gfeMatStatus",
    "rtlPriceEffectiveDate",
    "whslPriceEffectiveDate",
    "seasonName",
    "superSeason",
    "year",
];

let defaultBaseAttributeColumnView = {
    view_id: -1,
    view_data: {
        title: "System View",
        columnWidth: "0",
        widths: {},
        columns: tableAttributes.map(({ label, value }) => ({
            name: label,
            id: value,
            isSelected: defaultSelectedBasePricingAttributes.includes(value),
            pinned: attributeColumnDefMap[value].columnDef.pinned,
        })),
        view_tab: tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS,
    },
};

export const globalSearchAttributeColumnDefMap = cloneDeep(
    attributeColumnDefMap
);
globalSearchAttributeColumnDefMap.superSeason = {
    flag: true,
    columnDef: {
        headerName: "Super Season",
        field: "super_season",
    },
};
globalSearchAttributeColumnDefMap.year = {
    flag: true,
    columnDef: {
        headerName: "Year",
        field: "year",
    },
};

export const globalSearchTableAttributes = cloneDeep(tableAttributes);
globalSearchTableAttributes.unshift({
    label: "Super Season",
    value: "superSeason",
});
globalSearchTableAttributes.unshift({ label: "Year", value: "year" });

const defaultGlobalSearchAttributeColumnView = {
    view_id: -1,
    view_data: {
        title: "System View",
        columnWidth: "0",
        widths: {},
        columns: globalSearchTableAttributes.map(({ label, value }) => ({
            name: label,
            id: value,
            isSelected: defaultSelectedGlobalSearchAttributes.includes(value),
            pinned: globalSearchAttributeColumnDefMap[value].columnDef.pinned,
        })),
        view_tab: tableSettingTabIds.GLOBAL_SEARCH_ATTRIBUTE_SETTINGS,
    },
};

const defaultRegionalAttributeColumnView = {
    view_id: -1,
    view_data: {
        title: "System View",
        columnWidth: "0",
        widths: {},
        columns: attributeOptions.map(
            ({ label, value, showByDefault, pinned }) => ({
                name: label,
                id: value,
                isSelected: showByDefault,
                pinned,
            })
        ),
        view_tab: tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS,
    },
};

export const defaultPriceListAttributeColumnView = {
    view_id: -1,
    view_data: {
        title: "System View",
        columnWidth: "0",
        widths: {},
        columns: [],
        view_tab: tableSettingTabIds.PRICE_LIST_SETTINGS,
    },
};

const yearsBasedColumnsList = timePeriodOptions.map((item) => ({
    ...item,
    name: item.label,
    isDefault: true,
    isSelected: true,
    children: metricUnitsOptions.map((item) => ({
        ...item,
        name: item.label,
        isSelected: item.isDefault,
        children: typeOfUnitOptions.map((item) => ({
            ...item,
            name: item.label,
            isSelected: true,
            children: levelOfUnitOptions.map((item) => ({
                ...item,
                name: item.label,
                isSelected: true,
                id: Math.floor(Math.random() * 100000),
            })),
        })),
    })),
}));

const superSeasonColumnsList = seasonOptions.map((item) => ({
    ...item,
    name: item.label,
    isDefault: true,
    isSelected: true,
    children: yearsBasedColumnsList,
}));

const defaultBuyingRollUpsColumnView = {
    view_id: -1,
    view_data: {
        title: "System View",
        columnWidth: "0",
        widths: {},
        columns: superSeasonColumnsList,
        hierarchyOptions: hierarchyOptions.map((item) => ({
            ...item,
            isSelected: item.isDefault,
        })),
        view_tab: tableSettingTabIds.BUYING_ROLL_UPS,
    },
};

const defaultPlanningRollUpsColumnView = {
    view_id: -1,
    view_data: {
        title: "System View",
        columnWidth: "0",
        widths: {},
        columns: [
            {
                name: "Total",
                isDefault: true,
                isSelected: true,
                children: yearsBasedColumnsList,
            },
            {
                name: "Spring",
                isDefault: true,
                isSelected: true,
                children: superSeasonColumnsList,
            },
            {
                name: "Fall",
                isDefault: true,
                isSelected: true,
                children: superSeasonColumnsList,
            },
        ],
        hierarchyOptions: hierarchyOptions.map((item) => ({
            ...item,
            isSelected: item.isDefault,
        })),
        view_tab: tableSettingTabIds.PLANNING_ROLL_UPS,
    },
};

const getDefaultPageData = (
    isPlanGroupNameNeeded = false,
    isFilterByAttribute = false
) => {
    const data = {
        currentTab: "price-list",
        materials: [],
        isHistoricalData: false,
        chartData: {},
        countryLevelPrices: [],
        editedMaterials: [],
    };

    if (isPlanGroupNameNeeded) {
        data.selectedPlanGroup = null;
    }

    if (isFilterByAttribute) {
        data.currentPage = 0;
        data.selectedAttributeOptions = [];
        data.selectedSeparatorOptions = cloneDeep(separatorOptions);
        data.pasteAreaValue = "";
        data.validValues = [];
        data.invalidValues = [];
    }

    return data;
};

const getDefaultViewIndex = (views) => {
    const defaultViewIndex = views.findIndex((view) => view.is_active);

    return defaultViewIndex === -1 ? 0 : defaultViewIndex;
};

const initialState = {
    general: {
        selectedMarkets: [],
        selectedRegions: [],
        selectedChannels: [],
        selectedTab: 0,
        priceFileId: null,
        alertsAndExceptions: {},
        countryPriceAlertsAndExceptions: {},
        countryPriceRequestOperations: {},
        countryPriceRequestMaterials: [],
        countryPriceRequestPendingMaterials: [],
        countryPriceRequestCompletedMaterials: [],
        countryPriceGenerationStatusHandle: null,
        countryPriceGenerationStatusNotifications: {},
        isPollingInProgress: false,
        auditLogDownloadLinks: [],
        calledOnFileUploadFlag: false,
        missingMaterialsGPMNotification: [],
        isFetchingPriceLists: 0,
        priceLists: [],
        fromUploadToAttribute: false,
        columnSettings: {
            [tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS]: [
                defaultBaseAttributeColumnView,
            ],
            [tableSettingTabIds.GLOBAL_SEARCH_ATTRIBUTE_SETTINGS]: [
                defaultGlobalSearchAttributeColumnView,
            ],
            [tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS]: [
                defaultRegionalAttributeColumnView,
            ],
            [tableSettingTabIds.PRICE_LIST_SETTINGS]: [
                cloneDeep(defaultPriceListAttributeColumnView),
            ],
            [tableSettingTabIds.BUYING_ROLL_UPS]: [
                defaultBuyingRollUpsColumnView,
            ],
            [tableSettingTabIds.PLANNING_ROLL_UPS]: [
                defaultPlanningRollUpsColumnView,
            ],
        },
        columnSettingsOriginal: {
            [tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS]: [
                defaultBaseAttributeColumnView,
            ],
            [tableSettingTabIds.GLOBAL_SEARCH_ATTRIBUTE_SETTINGS]: [
                defaultGlobalSearchAttributeColumnView,
            ],
            [tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS]: [
                defaultRegionalAttributeColumnView,
            ],
            [tableSettingTabIds.PRICE_LIST_SETTINGS]: [
                cloneDeep(defaultPriceListAttributeColumnView),
            ],
            [tableSettingTabIds.BUYING_ROLL_UPS]: [
                defaultBuyingRollUpsColumnView,
            ],
            [tableSettingTabIds.PLANNING_ROLL_UPS]: [
                defaultPlanningRollUpsColumnView,
            ],
        },
        userResizedColumns: {
            [tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS]: {},
            [tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS]: {},
            [tableSettingTabIds.PRICE_LIST_SETTINGS]: {},
            [tableSettingTabIds.BUYING_ROLL_UPS]: {},
            [tableSettingTabIds.PLANNING_ROLL_UPS]: {},
            [tableSettingTabIds.GLOBAL_SEARCH_ATTRIBUTE_SETTINGS]: {},
        },
        selectedViewIndex: {
            [tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS]: 0,
            [tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS]: 0,
            [tableSettingTabIds.PRICE_LIST_SETTINGS]: 0,
            [tableSettingTabIds.BUYING_ROLL_UPS]: 0,
            [tableSettingTabIds.PLANNING_ROLL_UPS]: 0,
            [tableSettingTabIds.GLOBAL_SEARCH_ATTRIBUTE_SETTINGS]: 0,
        },
        approverEmails: [],
        ccEmails: [],
        innerTableToggleObject: null,
    },
    priceFileUpload: getDefaultPageData(),
    filterByAttributes: getDefaultPageData(false, true),
    filterByPlangroup: getDefaultPageData(true),
};

const formatMaterialDates = (materials) => {
    for (const material of materials) {
        material.first_available_dt = material.first_available_dt
            ? moment(material.first_available_dt).format("MM/DD/YYYY")
            : material.first_available_dt;

        material.msrp_effective_date = material.msrp_effective_date
            ? moment(material.msrp_effective_date).format("MM/DD/YYYY")
            : material.msrp_effective_date;

        material.wholesale_effective_date = material.wholesale_effective_date
            ? moment(material.wholesale_effective_date).format("MM/DD/YYYY")
            : material.wholesale_effective_date;
    }
};

const workbenchSlice = createSlice({
    name: "workbench",
    initialState,
    reducers: {
        setSelectedMarkets(state, action) {
            state.general.selectedMarkets = action.payload;
            state.general.selectedTab = 0;
        },
        setSelectedRegions(state, action) {
            state.general.selectedRegions = action.payload;
            state.general.selectedTab = 0;
        },
        setSelectedChannels(state, action) {
            state.general.selectedChannels = action.payload;
            state.general.selectedTab = 0;
        },
        setSelectedTab(state, action) {
            state.general.selectedTab = action.payload;
        },
        setPriceFileId(state, action) {
            state.general.priceFileId = action.payload;
        },
        setCurrentTab(state, action) {
            const { page, data } = action.payload;

            state[page].currentTab = data;
        },
        setCurrentPage(state, action) {
            const { page, data } = action.payload;

            if (!state[page]) state[page] = {};
            state[page].currentPage = data;
        },
        setSelectedAttributeOptions(state, action) {
            state.filterByAttributes.selectedAttributeOptions = action.payload;
        },
        setSelectedSeparatorOptions(state, action) {
            state.filterByAttributes.selectedSeparatorOptions = action.payload;
        },
        setPasteAreaValue(state, action) {
            state.filterByAttributes.pasteAreaValue = action.payload;
        },
        setValidValues(state, action) {
            state.filterByAttributes.validValues = action.payload;
        },
        setInvalidValues(state, action) {
            state.filterByAttributes.invalidValues = action.payload;
        },
        setMaterials(state, action) {
            const { page, data } = action.payload;

            formatMaterialDates(data);

            state[page].materials = data;
        },
        setIsHistoricalData(state, action) {
            const { isHistoricalData } = action.payload;

            state.general.isHistoricalData = isHistoricalData;
        },
        setChartData(state, action) {
            const { page, data } = action.payload;

            state[page].chartData = data;
        },
        setCountryLevelPrices(state, action) {
            const { page, data } = action.payload;

            formatMaterialDates(data);

            state[page].countryLevelPrices = data;
        },
        setMaterialAlertAndExceptions(state, action) {
            state.general.alertsAndExceptions = action.payload;
        },
        setCountryPriceAlertAndExceptions(state, action) {
            state.general.countryPriceAlertsAndExceptions = action.payload;
        },
        setFromUploadToAttribute(state, action) {
            state.fromUploadToAttribute = action.payload;
        },
        setCountryPriceRequestedMaterials(state, action) {
            const { pendingMaterialIds, completedMaterialIds } = action.payload;
            let diffFound = false;

            state.general.countryPriceRequestPendingMaterials =
                pendingMaterialIds;

            state.general.countryPriceRequestCompletedMaterials =
                completedMaterialIds;

            const cumulatedMaterials = [
                ...completedMaterialIds,
                ...pendingMaterialIds,
            ];
            const countryPriceRequestMaterials =
                state.general.countryPriceRequestMaterials;
            diffFound = false;

            for (const newMaterialId of cumulatedMaterials) {
                const foundIndex = countryPriceRequestMaterials.findIndex(
                    (id) => id === newMaterialId
                );

                if (foundIndex === -1) {
                    diffFound = true;
                }
            }

            for (const newMaterialId of countryPriceRequestMaterials) {
                const foundIndex = cumulatedMaterials.findIndex(
                    (id) => id === newMaterialId
                );

                if (foundIndex === -1) {
                    diffFound = true;
                }
            }

            if (diffFound) {
                state.general.countryPriceRequestMaterials = cumulatedMaterials;
            }
        },
        setCountryPriceGenerationStatusHandle(state, action) {
            clearTimeout(state.general.countryPriceGenerationStatusHandle);

            state.general.countryPriceGenerationStatusHandle = action.payload;
        },
        resetCountryPriceGenerationStatusHandle(state) {
            clearTimeout(state.general.countryPriceGenerationStatusHandle);

            state.general.countryPriceGenerationStatusHandle = null;
        },
        setCountryPriceGenerationStatusNotifications(state, action) {
            state.general.countryPriceGenerationStatusNotifications =
                action.payload;
        },
        setCountryPriceRequestOperations(state, action) {
            state.general.countryPriceRequestOperations = action.payload;
        },
        resetCountryPriceRequestOperations(state) {
            state.general.countryPriceRequestOperations = {};
        },
        setIsPollingInProgress(state) {
            state.general.isPollingInProgress = true;
        },
        resetIsPollingInProgress(state) {
            state.general.isPollingInProgress = false;
        },
        setMissingMaterialsGPMNotification(state, action) {
            state.general.missingMaterialsGPMNotification = action.payload;
        },
        setEditedMaterial(state, action) {
            const { page, data } = action.payload;

            state[page].editedMaterials = data;
        },
        resetPageData(state, action) {
            const { page } = action.payload;

            state[page] = getDefaultPageData(
                page === "filterByPlangroup",
                page === "filterByAttributes"
            );
        },
        setSelectedPlanGroup(state, action) {
            state.filterByPlangroup.selectedPlanGroup = action.payload;
        },
        setNotifyAuditLogDownloadLink(state, action) {
            const newLink = {
                timestamp: moment().toISOString(),
                link: action.payload,
            };

            state.general.auditLogDownloadLinks = [
                ...state.general.auditLogDownloadLinks,
                newLink,
            ];
        },
        setCalledOnFileUploadFlag(state) {
            state.general.calledOnFileUploadFlag = true;
        },
        resetCalledOnFileUploadFlag(state) {
            state.general.calledOnFileUploadFlag = false;
        },
        startFetchingPriceLists(state) {
            state.general.isFetching += 1;
        },
        endFetchingPriceLists(state) {
            state.general.isFetching -= 1;
        },
        setPriceLists(state, action) {
            state.general.priceLists = action.payload;
        },
        setColumnSettings(state, action) {
            defaultBaseAttributeColumnView = cloneDeep(
                defaultBaseAttributeColumnView
            );

            if (state.general.selectedChannels?.[0]?.label === "OUTLET") {
                const columnToExcludeCheck = [
                    "similarMaterial",
                    "smImage",
                    "smSeason",
                    "smMarket",
                    "smChannel",
                    "pfsWasMaterial",
                    "pfsWasImage",
                    "pfsWasSeason",
                    "pfsWasMarket",
                    "pfsWasIndicated",
                ];
                defaultBaseAttributeColumnView.view_data.columns =
                    filterAndMapColumnChannelWise(
                        defaultBaseAttributeColumnView.view_data.columns,
                        state.general.selectedChannels
                    )
                        .map((column) => {
                            const sortingOrder =
                                tableAttributesOutlet.findIndex(
                                    (item) => item.value === column.id
                                );

                            return {
                                ...column,
                                // TODO: Need to remove this check (this is to non-pilot users)
                                isSelected: columnToExcludeCheck.includes(
                                    column.id
                                )
                                    ? false
                                    : column.isSelected,
                                sortingOrder:
                                    sortingOrder != -1 ? sortingOrder : 1000,
                            };
                        })
                        .sort((a, b) => a.sortingOrder - b.sortingOrder);
            }

            const basePriceAttributeColumnSettings = [
                defaultBaseAttributeColumnView,
                ...action.payload
                    .filter(
                        (setting, index) =>
                            !setting.view_data.view_tab ||
                            (setting.view_data.view_tab ===
                                tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS &&
                                (index === 0 ||
                                    (!setting.view_data.channel &&
                                        state.general.selectedChannels?.[0]
                                            ?.label !== "OUTLET") ||
                                    state.general.selectedChannels?.[0]
                                        ?.label === setting.view_data.channel))
                    )
                    .map((e) => {
                        const alreadyPresent = e.view_data.columns.map(
                            (col) => col.id
                        );
                        defaultBaseAttributeColumnView.view_data.columns.forEach(
                            (col, idx) => {
                                if (!alreadyPresent.includes(col.id))
                                    e.view_data.columns.splice(
                                        idx,
                                        0,
                                        cloneDeep(col)
                                    );
                            }
                        );
                        return e;
                    })
                    .sort((a, b) => a.view_id - b.view_id),
            ];

            const globalSearchAttributeColumnSettings = [
                defaultGlobalSearchAttributeColumnView,
                ...action.payload
                    .filter(
                        (setting) =>
                            !setting.view_data.view_tab ||
                            setting.view_data.view_tab ===
                                tableSettingTabIds.GLOBAL_SEARCH_ATTRIBUTE_SETTINGS
                    )
                    .map((e) => {
                        const alreadyPresent = e.view_data.columns.map(
                            (col) => col.id
                        );
                        defaultGlobalSearchAttributeColumnView.view_data.columns.forEach(
                            (col, idx) => {
                                if (!alreadyPresent.includes(col.id))
                                    e.view_data.columns.splice(
                                        idx,
                                        0,
                                        cloneDeep(col)
                                    );
                            }
                        );
                        return e;
                    })
                    .sort((a, b) => a.view_id - b.view_id),
            ];

            const regionalPriceAttributeColumnSettings = [
                defaultRegionalAttributeColumnView,
                ...action.payload
                    .filter(
                        (setting, index) =>
                            setting.view_data.view_tab ===
                                tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS &&
                            (index === 0 ||
                                (!setting.view_data.channel &&
                                    state.general.selectedChannels?.[0]
                                        ?.label !== "OUTLET") ||
                                state.general.selectedChannels?.[0]?.label ===
                                    setting.view_data.channel)
                    )
                    .map((e) => {
                        const alreadyPresent = e.view_data.columns.map(
                            (col) => col.id
                        );
                        defaultRegionalAttributeColumnView.view_data.columns.forEach(
                            (col, idx) => {
                                if (!alreadyPresent.includes(col.id))
                                    e.view_data.columns.splice(
                                        idx,
                                        0,
                                        cloneDeep(col)
                                    );
                            }
                        );
                        return e;
                    })
                    .sort((a, b) => a.view_id - b.view_id),
            ];

            const priceListColumnSettings = [
                cloneDeep(defaultPriceListAttributeColumnView),
                ...action.payload
                    .filter(
                        (setting, index) =>
                            setting.view_data.view_tab ===
                                tableSettingTabIds.PRICE_LIST_SETTINGS &&
                            (index === 0 ||
                                (!setting.view_data.channel &&
                                    state.general.selectedChannels?.[0]
                                        ?.label !== "OUTLET") ||
                                state.general.selectedChannels?.[0]?.label ===
                                    setting.view_data.channel)
                    )
                    .sort((a, b) => a.view_id - b.view_id),
            ];

            const buyingRollUpsColumnSettings = [
                defaultBuyingRollUpsColumnView,
                ...action.payload
                    .filter(
                        (setting, index) =>
                            (!setting.view_data.view_tab ||
                                setting.view_data.view_tab ===
                                    tableSettingTabIds.BUYING_ROLL_UPS) &&
                            ((!setting.view_data.channel &&
                                state.general.selectedChannels?.[0]?.label !==
                                    "OUTLET") ||
                                state.general.selectedChannels?.[0]?.label ===
                                    setting.view_data.channel)
                    )
                    .map((e) => {
                        const alreadyPresent = e.view_data.columns.map(
                            (col) => col.id
                        );
                        defaultBuyingRollUpsColumnView.view_data.columns.forEach(
                            (col, idx) => {
                                if (!alreadyPresent.includes(col.id))
                                    e.view_data.columns.splice(
                                        idx,
                                        0,
                                        cloneDeep(col)
                                    );
                            }
                        );
                        return e;
                    })
                    .sort((a, b) => a.view_id - b.view_id),
            ];

            const planningRollUpsColumnSetting = [
                defaultPlanningRollUpsColumnView,
                ...action.payload
                    .filter(
                        (setting) =>
                            !setting.view_data.view_tab ||
                            setting.view_data.view_tab ===
                                tableSettingTabIds.PLANNING_ROLL_UPS
                    )
                    .map((e) => {
                        const alreadyPresent = e.view_data.columns.map(
                            (col) => col.id
                        );
                        defaultPlanningRollUpsColumnView.view_data.columns.forEach(
                            (col, idx) => {
                                if (!alreadyPresent.includes(col.id))
                                    e.view_data.columns.splice(
                                        idx,
                                        0,
                                        cloneDeep(col)
                                    );
                            }
                        );
                        return e;
                    })
                    .sort((a, b) => a.view_id - b.view_id),
            ];

            state.general.columnSettings = {
                [tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS]:
                    basePriceAttributeColumnSettings,
                [tableSettingTabIds.GLOBAL_SEARCH_ATTRIBUTE_SETTINGS]:
                    globalSearchAttributeColumnSettings,
                [tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS]:
                    regionalPriceAttributeColumnSettings,
                [tableSettingTabIds.PRICE_LIST_SETTINGS]:
                    priceListColumnSettings,
                [tableSettingTabIds.BUYING_ROLL_UPS]:
                    buyingRollUpsColumnSettings,
                [tableSettingTabIds.PLANNING_ROLL_UPS]:
                    planningRollUpsColumnSetting,
            };

            state.general.columnSettingsOriginal = {
                [tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS]:
                    basePriceAttributeColumnSettings,
                [tableSettingTabIds.GLOBAL_SEARCH_ATTRIBUTE_SETTINGS]:
                    globalSearchAttributeColumnSettings,
                [tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS]:
                    regionalPriceAttributeColumnSettings,
                [tableSettingTabIds.PRICE_LIST_SETTINGS]:
                    priceListColumnSettings,
                [tableSettingTabIds.BUYING_ROLL_UPS]:
                    buyingRollUpsColumnSettings,
                [tableSettingTabIds.PLANNING_ROLL_UPS]:
                    planningRollUpsColumnSetting,
            };

            state.general.selectedViewIndex = {
                [tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS]:
                    getDefaultViewIndex(basePriceAttributeColumnSettings),
                [tableSettingTabIds.GLOBAL_SEARCH_ATTRIBUTE_SETTINGS]:
                    getDefaultViewIndex(globalSearchAttributeColumnSettings),
                [tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS]:
                    getDefaultViewIndex(regionalPriceAttributeColumnSettings),
                [tableSettingTabIds.PRICE_LIST_SETTINGS]: getDefaultViewIndex(
                    priceListColumnSettings
                ),
                [tableSettingTabIds.BUYING_ROLL_UPS]: getDefaultViewIndex(
                    buyingRollUpsColumnSettings
                ),
                [tableSettingTabIds.PLANNING_ROLL_UPS]: getDefaultViewIndex(
                    planningRollUpsColumnSetting
                ),
            };
        },
        removeColumnSettings(state, action) {
            for (const tab of Object.values(tableSettingTabIds)) {
                const foundIndex = state.general.columnSettings[tab].findIndex(
                    (setting) => setting.view_id === action.payload
                );

                if (foundIndex === -1) {
                    continue;
                }

                const newColumnSettings = cloneDeep(
                    state.general.columnSettings[tab]
                );
                newColumnSettings.splice(foundIndex, 1);

                state.general.columnSettings[tab] = newColumnSettings;

                const newColumnSettingsOriginal = cloneDeep(
                    state.general.columnSettings[tab]
                );
                newColumnSettingsOriginal.splice(foundIndex, 1);

                state.general.columnSettingsOriginal[tab] =
                    newColumnSettingsOriginal;

                state.general.selectedViewIndex[tab] = 0;
            }
        },
        updateColumnSettings(state, action) {
            const newView = action.payload;

            const foundIndex = state.general.columnSettings[
                newView.view_data.view_tab
            ].findIndex(
                (setting) => setting.view_id === action.payload.view_id
            );

            if (foundIndex === -1) {
                return;
            }

            state.general.columnSettings[newView.view_data.view_tab][
                foundIndex
            ] = action.payload;
            state.general.columnSettingsOriginal[newView.view_data.view_tab][
                foundIndex
            ] = action.payload;

            state.general.userResizedColumns[newView.view_data.view_tab] = {};
        },
        insertColumnSetting(state, action) {
            const newView = action.payload;
            const tabId = newView.view_data.view_tab;

            state.general.columnSettings[tabId].push(action.payload);
            state.general.columnSettings[tabId].sort(
                (a, b) => a.view_id - b.view_id
            );

            state.general.columnSettingsOriginal[tabId].push(action.payload);
            state.general.columnSettingsOriginal[tabId].sort(
                (a, b) => a.view_id - b.view_id
            );

            state.general.userResizedColumns[tabId] = {};

            state.general.selectedViewIndex[tabId] =
                state.general.columnSettings[tabId].length - 1;
        },
        userUpdateColumnSettings(state, action) {
            const { tabId, views } = action.payload;

            state.general.columnSettings[tabId] = views;
        },
        setUserResizedColumns(state, action) {
            const { tabId, newData } = action.payload;

            state.general.userResizedColumns[tabId] = newData;
        },
        setSelectedViewIndex(state, action) {
            const { tabId, newData } = action.payload;

            state.general.selectedViewIndex[tabId] = newData;
        },
        setApproverAndCCEmails(state, action) {
            const { approverEmails, ccEmails } = action.payload;

            state.general.approverEmails = approverEmails.sort((a, b) =>
                a.toLowerCase() > b.toLowerCase() ? 1 : -1
            );
            state.general.ccEmails = ccEmails.sort((a, b) =>
                a.toLowerCase() > b.toLowerCase() ? 1 : -1
            );
        },
        setUpdatedHierarchyOptions(state, action) {
            const { tabId, viewIndex } = action.payload;

            state.general.columnSettings[tabId][
                viewIndex
            ].view_data.hierarchyOptions = action.payload.newData;
        },
        setInnerTableToggleObject(state, action) {
            state.general.innerTableToggleObject = action.payload;
        },
    },
});

// metricUnitsOptions,
//         seasonOptions,
//         timePeriodOptions,

export const {
    setMaterials,
    setIsHistoricalData,
    setSelectedMarkets,
    setSelectedRegions,
    setSelectedChannels,
    setSelectedTab,
    setCurrentTab,
    setCurrentPage,
    setSelectedAttributeOptions,
    setSelectedSeparatorOptions,
    setPasteAreaValue,
    setValidValues,
    setInvalidValues,
    setPriceFileId,
    setCountryLevelPrices,
    setMaterialAlertAndExceptions,
    setCountryPriceAlertAndExceptions,
    setFromUploadToAttribute,
    setChartData,
    setCountryPriceRequestedMaterials,
    setCountryPriceGenerationStatusHandle,
    resetCountryPriceGenerationStatusHandle,
    setCountryPriceGenerationStatusNotifications,
    setCountryPriceRequestOperations,
    resetCountryPriceRequestOperations,
    setIsPollingInProgress,
    resetIsPollingInProgress,
    setMissingMaterialsGPMNotification,
    setEditedMaterial,
    resetPageData,
    setSelectedPlanGroup,
    setNotifyAuditLogDownloadLink,
    setCalledOnFileUploadFlag,
    resetCalledOnFileUploadFlag,
    startFetchingPriceLists,
    endFetchingPriceLists,
    setPriceLists,
    setColumnSettings,
    removeColumnSettings,
    updateColumnSettings,
    insertColumnSetting,
    userUpdateColumnSettings,
    setUserResizedColumns,
    setSelectedViewIndex,
    setApproverAndCCEmails,
    setUpdatedHierarchyOptions,
    setInnerTableToggleObject,
} = workbenchSlice.actions;

export default workbenchSlice.reducer;

const materialIdsForAlertsAndExceptions = (countryLevelPrices, materialData) =>
    countryLevelPrices.filter(
        (material) =>
            materialData.findIndex(
                (md) =>
                    md.data.style_number === material.style_number ||
                    material.item_number === md.data.item_number
            ) !== -1
    );
export const saveCountryLevelPrices =
    ({ page, reqBody, materialData }) =>
    (dispatch, getState) => {
        dispatch(requestStart());

        const indexFinder = (temp, data) =>
            temp.findIndex(
                (e) =>
                    e.material_number === data.material_number &&
                    e.season_code === data.season_code
            );

        return API.put("price-file-material-generated-prices", reqBody)
            .then((res) => {
                const { data: resData } = res.data;
                const {
                    workbench: {
                        general: {
                            selectedMarkets: [selectedMarket],
                            selectedRegions: [selectedRegion],
                            selectedChannels: [selectedChannel],
                        },
                        [page]: { countryLevelPrices },
                    },
                } = getState();

                dispatch(
                    requestComplete({
                        success: true,
                        successMessage:
                            "Successfully updated the material prices",
                    })
                );

                const newCountryLevelPrices = cloneDeep(countryLevelPrices);

                const resNewFormat = {};
                resData.forEach((e) => {
                    if (!resNewFormat[e.price_file_material_id])
                        resNewFormat[e.price_file_material_id] = [];
                    resNewFormat[e.price_file_material_id].push(e);
                });
                materialData.forEach((row) => {
                    const { data } = row;
                    const targetRowIdx = indexFinder(
                        newCountryLevelPrices,
                        data
                    );
                    const { country_data, id } =
                        newCountryLevelPrices[targetRowIdx];
                    const newData = resNewFormat[id];

                    if (newData)
                        for (const newPrice of newData) {
                            const { price_list_name } = newPrice;
                            const oldPrice = country_data[price_list_name];

                            if (
                                newPrice.id ===
                                oldPrice.price_file_generated_price_id
                            ) {
                                oldPrice.msrp_generated_value =
                                    newPrice.msrp_generated_value;
                                oldPrice.wholesale_generated_value =
                                    newPrice.wholesale_generated_value;
                                oldPrice.sync_with_gfe_status =
                                    newPrice.sync_with_gfe_status;
                            }
                        }
                });

                dispatch(
                    setCountryLevelPrices({
                        page,
                        data: newCountryLevelPrices,
                    })
                );

                // Fetch alert and exception for edited material
                if (selectedMarket && selectedRegion && selectedChannel) {
                    return dispatch(
                        getAlertsAndExceptionsAndUpdateState({
                            screen: "country_level_price",
                            material_ids: countryLevelPrices.map(
                                (material) =>
                                    material.material_number +
                                    material.season_code
                            ),
                            market_id: selectedMarket.value,
                            region: selectedRegion.label,
                            channel: selectedChannel.label,
                        })
                    );
                }
            })
            .catch((err) => {
                const message =
                    err?.response?.data?.message ??
                    "Regional price edit failed";

                dispatch(requestFail(message));
            });
    };
export const syncToGFE =
    ({ page, reqBody, countryLevelPriceFetchData }) =>
    (dispatch) => {
        dispatch(requestStart());

        return API.post("price-file/gfe-price-propagation", reqBody)
            .then(() => {
                dispatch(requestComplete());

                return dispatch(
                    fetchCountryLevelPrices({
                        page,
                        data: countryLevelPriceFetchData,
                    })
                );
            })
            .catch((err) => {
                dispatch(
                    requestFail(
                        err?.response?.data?.message || err?.message || err
                    )
                );
            });
    };
export const fetchAuditLogData = (reqBody) => {
    return API.post("audit-logs-v2", reqBody);
};
export const downloadAuditLogData = (reqBody) => {
    return API.post("price-file-audit-logs-async", reqBody).catch((err) => {
        throw err?.response?.data?.message || err?.message || err;
    });
};

export const fetchCountryLevelPrices =
    ({ page, data }) =>
    (dispatch, getStore) => {
        dispatch(requestCompStart("regionalPricing"));

        return API.post("filter-price-file-material-generated-prices", data)
            .then((res) => {
                const {
                    workbench: {
                        general: {
                            selectedMarkets: [selectedMarket],
                            selectedRegions: [selectedRegion],
                            selectedChannels: [selectedChannel],
                            priceLists,
                        },
                    },
                } = getStore();

                const mappingObjectPriceLists = priceLists
                    .filter((item) => item.channel === selectedChannel?.label)
                    .reduce((a, item) => {
                        a[item.name] = item.id;
                        return a;
                    }, {});

                res.data.data.forEach((item) => {
                    Object.keys(item.country_data).forEach((key) => {
                        if (mappingObjectPriceLists[key])
                            item.country_data[key].price_list_id =
                                mappingObjectPriceLists[key];
                    });
                });

                dispatch(setCountryLevelPrices({ page, data: res.data.data }));

                dispatch(requestCompComplete("regionalPricing"));

                if (
                    res.data.data.length &&
                    selectedMarket &&
                    selectedRegion &&
                    selectedChannel
                ) {
                    const materialIds = res.data.data.map(
                        (material) =>
                            material.material_number + material.season_code
                    );

                    dispatch(
                        getAlertsAndExceptions({
                            screen: "country_level_price",
                            material_ids: materialIds,
                            market_id: selectedMarket.value,
                            region: selectedRegion.label,
                            channel: selectedChannel.label,
                        })
                    );
                }
            })
            .catch((err) => {
                console.error(err);
                const message =
                    err?.response?.data?.message ??
                    "Regional prices fetch failed";

                dispatch(requestCompFail({ message, comp: "regionalPricing" }));
            });
    };

export const toggleLockAPI = (data) => (dispatch) => {
    return API.put("/lock_unlock_whsl_msrp_price", { data }).catch((err) => {
        dispatch(toastError(err?.message || "Something went wrong"));
        return { error: true };
    });
};

export const uploadPriceFile = (data) => (dispatch) => {
    const { file, marketId, region, channel, isInsert = false } = data;

    const url = `/price-files/upload/?file_name=${file.name}&market_id=${marketId}&region=${region}&channel=${channel}&is_insert=${isInsert}`;
    const formData = new FormData();

    formData.append("file", file);

    dispatch(requestStart());

    return API.post(url, formData)
        .then((res) => {
            const { code, data, message } = res.data;

            if (code === 200) {
                // EVERYTHING IS FINE
                dispatch(setFromUploadToAttribute(true));
                dispatch(setPriceFileId(data.price_file_id));
                dispatch(
                    requestComplete({
                        success: true,
                        successMessage: "Price File uploaded successfully",
                    })
                );
                return { status: true, price_file_id: data.price_file_id };
            } else if (code === 400 && data.price_file_id) {
                // THE FILE IS VALID BUT SOME MATERIALS ARE NOT, HENCE 400
                dispatch(
                    requestComplete({
                        success: true,
                        successMessage: "Price File uploaded successfully",
                    })
                );
                return {
                    status: false,
                    message: message,
                    price_file_id: data.price_file_id,
                    validCount: data.valid_material_count,
                    validMaterialWithoutPed: data.valid_material_without_ped,
                    csvMaterialCount: data.csv_material_count,
                };
            } else {
                dispatch(toastError(message));
                throw message;
            }
        })
        .catch((err) => {
            dispatch(requestFail(err?.message || err));
            throw err;
        });
};

export const uploadDiscountFile = (data) => (dispatch) => {
    const { file, marketId, region, channel, isInsert = false } = data;

    const url = `/price-files/discount_upload/?file_name=${file.name}&market_id=${marketId}&region=${region}&channel=${channel}&is_insert=${isInsert}`;
    const formData = new FormData();

    formData.append("file", file);

    dispatch(requestStart());

    return API.post(url, formData)
        .then((res) => {
            const { code, data, message } = res.data;

            if (code === 200) {
                // EVERYTHING IS FINE
                dispatch(
                    requestComplete({
                        success: true,
                        successMessage: "Discount File uploaded successfully",
                    })
                );
                return { status: true, price_file_id: data.price_file_id };
            } else if (code === 400 && data.price_file_id) {
                // THE FILE IS VALID BUT SOME MATERIALS ARE NOT, HENCE 400
                dispatch(requestComplete());

                return {
                    status: false,
                    message: message,
                    price_file_id: data.price_file_id,
                    validCount: data.valid_material_count,
                    validMaterialWithoutPed: data.valid_material_without_ped,
                    csvMaterialCount: data.csv_material_count,
                };
            } else {
                dispatch(toastError(message));
                throw message;
            }
        })
        .catch((err) => {
            dispatch(requestFail(err?.message || err));
            throw err;
        });
};

export const addNotifications = (data, internalCall) => (dispatch) => {
    dispatch(notificationsFetchStart());

    return API.post("/missing-gpm-generation-status", data)
        .then((res) => {
            dispatch(setMissingMaterialsGPMNotification(res?.data?.data));
            dispatch(setNewNotificationsCount(res?.data?.data?.length));
            return res;
        })
        .catch(() => {
            dispatch(toastError("GPM material insertion status fetch failed"));
        })
        .then((res) => {
            dispatch(notificationsFetchStop());

            if (
                res?.data?.data?.find(
                    (e) => !e.missing_gpm_price_generation_status
                )
            ) {
                if (!internalCall) {
                    dispatch(
                        toastMessage(
                            "Materials are being retrieved from GFE. Please follow notifications for updates"
                        )
                    );
                }
                setTimeout(() => {
                    dispatch(addNotifications(data, true));
                }, 10000);
            } else if (
                res?.data?.data?.find((e) => e.pending === 0 && e.completed > 0)
            ) {
                dispatch(toastSuccessMessage(res?.data?.data?.[0].message));

                dispatch(setSelectedTab(2));

                dispatch(
                    setCurrentPage({ page: "filterByAttributes", data: 1 })
                );
                dispatch(
                    getPriceFileMaterials({ page: "filterByAttributes", data })
                ).then((res) => {
                    if (!res.table_data.length) {
                        dispatch(
                            toastError(
                                "No material tagged for the selection you have made"
                            )
                        );
                    }

                    dispatch(
                        fetchCountryLevelPrices({
                            page: "filterByAttributes",
                            data: {
                                ...data,
                                price_file_material_ids: res.table_data.map(
                                    (e) => e.id
                                ),
                            },
                        })
                    );
                });
            }
        });
};

export const deletedUploadedPriceFile = (data) => {
    return API.post(`/price-file/${data.id}`);
};

export const downloadInvalidMaterials = (data) => {
    return API.post(
        `price-file/invalid_materials/${data.id}?channel=${data.channel}`
    );
};

export const downloadInvalidDiscount = (data) => {
    return API.post(`price-file/invalid_materials_discount/${data.id}`);
};

export const getAlertsAndExceptions = (data) => (dispatch) => {
    dispatch(
        requestCompStart(
            data.screen === "price_grid"
                ? "basePricingAlertsConfig"
                : "regionalPricingAlertsConfig"
        )
    );

    return API.post("/price-file/alerts-config", data)
        .then((response) => {
            const { data: resData } = response.data;

            if (data.screen === "price_grid") {
                dispatch(setMaterialAlertAndExceptions(resData));
            } else if (data.screen === "country_level_price") {
                dispatch(setCountryPriceAlertAndExceptions(resData));
            }

            dispatch(
                requestCompComplete(
                    data.screen === "price_grid"
                        ? "basePricingAlertsConfig"
                        : "regionalPricingAlertsConfig"
                )
            );
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ??
                "Alerts and exceptions fetch failed";

            dispatch(
                requestCompFail({
                    message,
                    comp:
                        data.screen === "price_grid"
                            ? "basePricingAlertsConfig"
                            : "regionalPricingAlertsConfig",
                })
            );
        });
};

export const getAlertsAndExceptionsAndUpdateState =
    (data, dontShowLoader) => (dispatch, getState) => {
        if (!dontShowLoader) {
            dispatch(
                requestCompStart(
                    data.screen === "price_grid"
                        ? "basePricingAlertsConfig"
                        : "regionalPricingAlertsConfig"
                )
            );
            dispatch(requestStart());
        }

        return API.post("/price-file/alerts-config", data)
            .then((response) => {
                const { data: resData } = response.data;
                const {
                    workbench: {
                        general: {
                            alertsAndExceptions,
                            countryPriceAlertsAndExceptions,
                        },
                    },
                } = getState();

                if (data.screen === "price_grid") {
                    const newAlertsAndExceptions =
                        cloneDeep(alertsAndExceptions);

                    for (const key of data.material_ids) {
                        if (resData[key]) {
                            newAlertsAndExceptions[key] = resData[key];
                        } else {
                            delete newAlertsAndExceptions[key];
                        }
                    }

                    dispatch(
                        setMaterialAlertAndExceptions(newAlertsAndExceptions)
                    );
                } else if (data.screen === "country_level_price") {
                    const newCountryPriceAlertsAndExceptions = cloneDeep(
                        countryPriceAlertsAndExceptions
                    );

                    for (const key of data.material_ids) {
                        if (resData[key]) {
                            newCountryPriceAlertsAndExceptions[key] =
                                resData[key];
                        } else {
                            delete newCountryPriceAlertsAndExceptions[key];
                        }
                    }

                    dispatch(
                        setCountryPriceAlertAndExceptions(
                            newCountryPriceAlertsAndExceptions
                        )
                    );
                }

                if (!dontShowLoader) {
                    dispatch(
                        requestCompComplete(
                            data.screen === "price_grid"
                                ? "basePricingAlertsConfig"
                                : "regionalPricingAlertsConfig"
                        )
                    );
                    dispatch(requestComplete());
                }
            })
            .catch((err) => {
                const message =
                    err?.response?.data?.message ??
                    "Alerts and exceptions fetch failed";

                if (!dontShowLoader) {
                    dispatch(
                        requestCompFail({
                            message,
                            comp:
                                data.screen === "price_grid"
                                    ? "basePricingAlertsConfig"
                                    : "regionalPricingAlertsConfig",
                        })
                    );
                    dispatch(requestFail(message));
                }
            });
    };

export const getSimilarOrPfsAPI = (data, type) =>
    API.post("/get_top_one_materials", {
        ...data,
        search_type: type,
    });

export const getSimilarAndPFSMaterials =
    (data, page) => (dispatch, getStore) => {
        dispatch(requestCompStart("similarAndPfsMaterials"));

        const materials = cloneDeep(getStore().workbench[page].materials);

        return getSimilarOrPfsAPI(data, "similar", page).then((similarRes) => {
            return getSimilarOrPfsAPI(data, "pfs", page).then((pfsRes) => {
                similarRes.data?.data?.map((sr) => {
                    const idx = materials.findIndex(
                        (m) => sr.source_id === m.id
                    );
                    if (idx !== -1) {
                        materials[idx].similar_material = sr.material_number;
                        materials[idx].similar_material_image_url =
                            sr.image_url;
                        materials[idx].similar_material_season = sr.season;
                        materials[idx].similar_material_market_name =
                            sr.market_name;
                        materials[idx].similar_material_channel = sr.channel;
                        materials[idx].similar_material_update_type =
                            sr.update_type;
                    }
                });
                pfsRes.data?.data?.map((pr) => {
                    const idx = materials.findIndex(
                        (m) => pr.source_id === m.id
                    );
                    if (idx !== -1) {
                        materials[idx].pfs_was_material = pr.material_number;
                        materials[idx].pfs_was_image_url = pr.image_url;
                        materials[idx].pfs_was_material_season = pr.season;
                        materials[idx].pfs_was_market_name = pr.market_name;
                        materials[idx].pfs_was_indicative =
                            pr.pfs_was_indicative;
                        materials[idx].msrp_price = pr.msrp_price;
                        materials[idx].store_price = pr.store_price;
                        materials[idx].pfs_was_material_update_type =
                            pr.update_type;
                    }
                });
                dispatch(
                    setMaterials({
                        page,
                        data: materials,
                    })
                );

                dispatch(setFromUploadToAttribute(false));
                dispatch(requestCompComplete("similarAndPfsMaterials"));
            });
        });
    };

export const getPriceFileMaterials =
    ({ page, data }) =>
    (dispatch, getStore) => {
        dispatch(requestCompStart("basePricing"));

        return API.post("/price-file-material-details", data)
            .then((response) => {
                const { data } = response.data;

                dispatch(
                    setMaterials({
                        page,
                        data: data.table_data.map((e) => {
                            const costOrigin =
                                (e.tfg_manual && "TFG") ||
                                (e.std_cost && "STANDARD COST") ||
                                "-";
                            const costUsed = e.tfg_manual || e.std_cost || "-";
                            return { ...e, costOrigin, costUsed };
                        }),
                    })
                );
                dispatch(setChartData({ page, data: data.chart_data }));

                dispatch(requestCompComplete("basePricing"));

                const {
                    workbench: {
                        general: {
                            selectedMarkets: [selectedMarket],
                            selectedRegions: [selectedRegion],
                            selectedChannels: [selectedChannel],
                        },
                        fromUploadToAttribute,
                    },
                } = getStore();

                const isHistoricalData = HISTORICAL_DATA.includes(
                    selectedMarket.label
                );

                dispatch(
                    setIsHistoricalData({
                        page,
                        isHistoricalData,
                    })
                );

                setTimeout(() => {
                    if (
                        data.table_data.length &&
                        selectedMarket &&
                        selectedRegion &&
                        selectedChannel
                    ) {
                        const materialIdsWithSeason = data.table_data.map(
                            (material) =>
                                material.material_number + material.season_code
                        );
                        const pfmIds = data.table_data.map(
                            (material) => material.id
                        );

                        if (!isHistoricalData) {
                            (selectedChannel.label === "OUTLET" &&
                            fromUploadToAttribute
                                ? dispatch(
                                      getSimilarAndPFSMaterials(
                                          {
                                              region: selectedRegion.label,
                                              channel: selectedChannel.label,
                                              pfm_id: pfmIds,
                                              market_id: selectedMarket.value,
                                              fetch_type: "upload",
                                          },
                                          page
                                      )
                                  )
                                : Promise.resolve()
                            ).then(() => {
                                dispatch(
                                    getAlertsAndExceptions({
                                        screen: "price_grid",
                                        material_ids: materialIdsWithSeason,
                                        market_id: selectedMarket.value,
                                        region: selectedRegion.label,
                                        channel: selectedChannel.label,
                                    })
                                );
                            });
                        }
                    }
                }, 0);

                return data;
            })
            .catch((err) => {
                const message =
                    err?.response?.data?.message ?? "Base prices fetch failed";

                dispatch(requestCompFail({ message, comp: "basePricing" }));
            });
    };

export const generateCountryLevelPrice = (data) => (dispatch) => {
    dispatch(requestStart());

    return API.post("/price-file-material-generated-prices-via-cloud", data)
        .then(() => {
            const { market_id, region, channel } = data;

            dispatch(getPriceGenerationStatus({ market_id, region, channel }))
                .then(() => {
                    dispatch(
                        toastMessage(
                            "Regional price generation initiated. Please follow notifications for further updates"
                        )
                    );
                })
                .finally(() => {
                    dispatch(requestComplete());
                });
        })
        .catch((res) => {
            if (res.status === 422) {
                dispatch(requestFail(res.data.message));
            } else {
                const message =
                    res?.response?.data?.message ??
                    "Regional price generation failed";

                dispatch(requestFail(message));
            }
        });
};

export const refreshPriceRule = (data) => (dispatch) => {
    dispatch(requestStart());

    return API.post("/apply-price-rule-for-materials-prices", data)
        .then(() => {
            const { market_id, region, channel } = data;

            dispatch(getPriceGenerationStatus({ market_id, region, channel }))
                .then(() => {
                    dispatch(
                        toastMessage(
                            "Price rule refresh initiated. Please follow notifications for further updates"
                        )
                    );
                })
                .finally(() => {
                    dispatch(requestComplete());
                });
        })
        .catch((res) => {
            if (res.status === 422) {
                dispatch(requestFail(res.data.message));
            } else {
                const message =
                    res?.response?.data?.message ?? "Price rule refresh failed";

                dispatch(requestFail(message));
            }
        });
};

export const getPriceFileMaterialsAndCountryLevelPrices =
    (page, data) => (dispatch) => {
        return dispatch(getPriceFileMaterials({ page, data })).then((res) => {
            dispatch(
                fetchCountryLevelPrices({
                    page,
                    data: {
                        ...data,
                        price_file_material_ids: res.table_data.map(
                            (e) => e.id
                        ),
                    },
                })
            );
        });
    };

export const resetMaterialDates =
    ({ page, data, materialFetchData, fetchMaterialsFlag = true }) =>
    (dispatch) => {
        dispatch(requestStart());

        return API.post("/price-file/reset_dates", data)
            .then((res) => {
                const { message } = res.data;

                dispatch(
                    fetchMaterialsFlag
                        ? requestComplete({
                              success: true,
                              successMessage: message,
                          })
                        : requestComplete()
                );

                if (fetchMaterialsFlag) {
                    dispatch(
                        getPriceFileMaterialsAndCountryLevelPrices(
                            page,
                            materialFetchData
                        )
                    );
                }
                return message;
            })
            .catch((err) => {
                throw new Error(
                    err?.response?.data?.message ??
                        "Effective dates reset failed"
                );
            });
    };

export const editMaterial =
    ({ page, data, materialFetchData }) =>
    (dispatch, getState) => {
        dispatch(requestStart());

        return API.post("/price-file/edit_dates_and_price", data)
            .then((res) => {
                const { message, data: resData } = res.data;
                const {
                    workbench: {
                        general: {
                            selectedMarkets: [selectedMarket],
                            selectedRegions: [selectedRegion],
                            selectedChannels: [selectedChannel],
                        },
                        [page]: { materials },
                    },
                } = getState();
                const {
                    material,
                    season,
                    msrp_effective_date,
                    wholesale_effective_date,
                    msrp_price,
                } = data;
                const {
                    margin_dtc_actual,
                    margin_dtc_perc,
                    margin_dtc_projected,
                    margin_wholesale_actual,
                    margin_wholesale_perc,
                    margin_wholesale_projected,
                    sales_dtc_actual,
                    sales_dtc_projected,
                    sales_wholesale_actual,
                    sales_wholesale_projected,
                    wholesale_price,
                    store_price,
                } = resData;

                dispatch(
                    requestComplete({ success: true, successMessage: message })
                );

                materialFetchData.price_file_material_ids = materials.map(
                    (material) => material.id
                );

                dispatch(
                    fetchCountryLevelPrices({ page, data: materialFetchData })
                );

                // Fetch alert and exception for the editted material

                if (selectedMarket && selectedRegion && selectedChannel) {
                    dispatch(
                        getAlertsAndExceptionsAndUpdateState({
                            screen: "price_grid",
                            material_ids: materials.map(
                                (material) =>
                                    material.material_number +
                                    material.season_code
                            ),
                            market_id: selectedMarket.value,
                            region: selectedRegion.label,
                            channel: selectedChannel.label,
                        })
                    );
                }

                // Apply edit on state

                const newMaterials = cloneDeep(materials);

                for (const newMaterial of newMaterials) {
                    const { material_number, season_code } = newMaterial;

                    if (
                        material === material_number &&
                        season === season_code
                    ) {
                        newMaterial.wholesale_effective_date =
                            wholesale_effective_date;
                        newMaterial.msrp_effective_date = msrp_effective_date;
                        newMaterial.msrp_price = msrp_price;
                        newMaterial.margin_dtc_actual = margin_dtc_actual;
                        newMaterial.margin_dtc_perc = margin_dtc_perc;
                        newMaterial.margin_dtc_projected = margin_dtc_projected;
                        newMaterial.margin_wholesale_actual =
                            margin_wholesale_actual;
                        newMaterial.margin_wholesale_perc =
                            margin_wholesale_perc;
                        newMaterial.margin_wholesale_projected =
                            margin_wholesale_projected;
                        newMaterial.sales_dtc_actual = sales_dtc_actual;
                        newMaterial.sales_dtc_projected = sales_dtc_projected;
                        newMaterial.sales_wholesale_actual =
                            sales_wholesale_actual;
                        newMaterial.sales_wholesale_projected =
                            sales_wholesale_projected;
                        newMaterial.wholesale_price = wholesale_price;
                        if (selectedChannel.label === "OUTLET")
                            newMaterial.store_price = store_price;
                    }
                }

                let selectedMaterial = newMaterials.find(
                    (item) =>
                        item.material_number === data.material &&
                        item.season_code === data.season
                );

                if (selectedMaterial) {
                    selectedMaterial.discount = data.discount;
                }

                dispatch(setMaterials({ page, data: newMaterials }));
            })
            .catch((err) => {
                const message =
                    err?.response?.data?.message ?? "Material edit failed";

                dispatch(requestFail(message));
            });
    };

export const bulkEditMaterial =
    ({ page, data, materialFetchData, fetchMaterialsFlag = true }) =>
    (dispatch) => {
        dispatch(requestStart());

        return API.post("/price-file/edit-dates", data)
            .then((res) => {
                dispatch(requestComplete());

                if (fetchMaterialsFlag) {
                    dispatch(
                        getPriceFileMaterialsAndCountryLevelPrices(
                            page,
                            materialFetchData
                        )
                    );
                }
                return res.data?.message;
            })
            .catch((err) => {
                throw new Error(
                    err?.response?.data?.message ?? "Materials bulk edit failed"
                );
            });
    };

export const untagMaterials =
    ({ page, data, materialFetchData, fetchMaterialsFlag = true }) =>
    (dispatch) => {
        dispatch(requestStart());

        return API.post("/untag-price-file-material-details", data)
            .then(() => {
                dispatch(
                    requestComplete({
                        success: true,
                        successMessage: "Material untagged successfully",
                    })
                );

                if (fetchMaterialsFlag) {
                    dispatch(
                        getPriceFileMaterialsAndCountryLevelPrices(
                            page,
                            materialFetchData
                        )
                    );
                }
            })
            .catch((err) => {
                const message =
                    err?.response?.data?.message ?? "Materials untag failed";

                dispatch(requestFail(message));
            });
    };

export const getPriceGenerationStatus =
    ({ market_id, region, channel }) =>
    (dispatch, getState) => {
        const payload = {
            method_type: "GET",
            market_id,
            region,
            channel,
            req_id: null,
        };

        dispatch(notificationsFetchStart());
        dispatch(setIsPollingInProgress());

        return API.post("/price-generation-status", payload)
            .then((res) => {
                const currentState = getState();
                const { data: resData } = res.data;

                if (!currentState.workbench.general.isPollingInProgress) {
                    return;
                }

                const countryPriceRequestOperationsCopy = cloneDeep(
                    currentState.workbench.general.countryPriceRequestOperations
                );
                const completedMaterialIds = [];
                const pendingMaterialIds = [];

                for (const operation of resData) {
                    countryPriceRequestOperationsCopy[operation.id] = operation;

                    if (
                        operation.pending_material_ids.length === 0 &&
                        operation.completed_material_ids.length > 0
                    ) {
                    } else {
                        const operationCompletedMaterialIds =
                            operation.completed_material_ids;
                        const operationPendingMaterialIds =
                            operation.pending_material_ids;

                        for (const opMaterialId of operationPendingMaterialIds) {
                            const foundIndex = pendingMaterialIds.findIndex(
                                (id) => id === opMaterialId
                            );

                            if (foundIndex === -1) {
                                pendingMaterialIds.push(opMaterialId);
                            }
                        }

                        for (const opMaterialId of operationCompletedMaterialIds) {
                            const foundIndex = completedMaterialIds.findIndex(
                                (id) => id === opMaterialId
                            );
                            const foundIndexInPendingMaterials =
                                pendingMaterialIds.findIndex(
                                    (id) => id === opMaterialId
                                );

                            if (
                                foundIndex === -1 &&
                                foundIndexInPendingMaterials === -1
                            ) {
                                completedMaterialIds.push(opMaterialId);
                            }
                        }
                    }
                }

                dispatch(
                    setCountryPriceRequestOperations(
                        countryPriceRequestOperationsCopy
                    )
                );

                dispatch(
                    setCountryPriceRequestedMaterials({
                        pendingMaterialIds,
                        completedMaterialIds,
                    })
                );

                //
                // --- Notifications section ---
                //

                const { countryPriceGenerationStatusNotifications } =
                    currentState.workbench.general;
                const countryPriceGenerationStatusNotificationsCopy = cloneDeep(
                    countryPriceGenerationStatusNotifications
                );

                let newNotificationCount = 0;

                for (const op of resData) {
                    if (
                        countryPriceGenerationStatusNotificationsCopy[op.id] !==
                        op.message
                    ) {
                        newNotificationCount++;
                    }

                    countryPriceGenerationStatusNotificationsCopy[op.id] =
                        op.message;
                }

                dispatch(
                    setCountryPriceGenerationStatusNotifications(
                        countryPriceGenerationStatusNotificationsCopy
                    )
                );

                const generatedNotifications = Object.values(
                    countryPriceGenerationStatusNotificationsCopy
                );

                dispatch(setNotifications(generatedNotifications));
                dispatch(setNewNotificationsCount(newNotificationCount));

                //
                // Auto-fetch on operation completion
                //

                const selectedTab = currentState.workbench.general.selectedTab;

                if (selectedTab > 1 && selectedTab < 4) {
                    for (const operation of resData) {
                        if (operation.pending === 0) {
                            const selectedFilters =
                                currentState.filters.savedFilters[
                                    selectedTab === 2
                                        ? "EU_VIEW_BY_FILTERING_ATTRIBUTES"
                                        : "EU_VIEW_BY_PLAN_GROUPS"
                                ];

                            const {
                                selectedMarkets,
                                selectedRegions,
                                selectedChannels,
                                priceFileId,
                            } = currentState.workbench.general;

                            const data = getMaterialFetchData(
                                selectedFilters,
                                selectedMarkets,
                                selectedRegions,
                                selectedChannels,
                                priceFileId
                            );

                            dispatch(
                                getPriceFileMaterialsAndCountryLevelPrices(
                                    selectedTab === 2
                                        ? "filterByAttributes"
                                        : "filterByPlangroup",
                                    data
                                )
                            );
                        }
                    }
                }

                // Notification for completed operations

                let msg = "";

                for (const operation of resData) {
                    if (operation.pending === 0) {
                        msg = operation.message;
                    }
                }

                if (msg.length) {
                    dispatch(toastSuccessMessage(msg));
                }
            })
            .catch(() => {
                dispatch(toastError("Price generation status fetch failed"));
            })
            .finally(() => {
                dispatch(notificationsFetchStop());

                const currentState = getState();

                if (
                    currentState.workbench.general
                        .countryPriceRequestPendingMaterials.length > 0
                ) {
                    dispatch(resetCountryPriceGenerationStatusHandle());

                    const timeoutHandle = setTimeout(() => {
                        dispatch(
                            getPriceGenerationStatus({
                                market_id,
                                region,
                                channel,
                            })
                        );
                    }, 10000);

                    dispatch(
                        setCountryPriceGenerationStatusHandle(timeoutHandle)
                    );
                }
            });
    };

export const validatePastedAttributes = (data) => (dispatch) => {
    dispatch(requestStart());

    return API.post("/attribute-filters/validate", data)
        .then((res) => {
            dispatch(requestComplete());

            return res.data.data;
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ??
                "Attribute values validation failed";

            dispatch(requestFail(message));

            throw message;
        });
};

export const fetchPriceLists = () => (dispatch) => {
    dispatch(startFetchingPriceLists());
    dispatch(requestStart());

    return API("/price-lists")
        .then((res) => {
            const { data } = res.data;

            dispatch(setPriceLists(data));

            return data;
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ??
                "Price list details fetch failed";

            dispatch(toastError(message));

            throw message;
        })
        .finally(() => {
            dispatch(endFetchingPriceLists());
            dispatch(requestComplete());
        });
};

export const getColumnSettings = () => (dispatch) => {
    dispatch(requestStart());

    return API("/custom_column")
        .then((res) => {
            const { data } = res.data;

            dispatch(setColumnSettings(data));
            return data;
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Column settings fetch failed";

            dispatch(toastError(message));
        })
        .finally(() => {
            dispatch(requestComplete());
        });
};

export const deleteColumnSetting = (settingId) => (dispatch) => {
    if (typeof settingId !== "number" || isNaN(settingId)) {
        return new Promise.reject("Invalid settings ID");
    }

    dispatch(requestStart());

    return API.post("/delete_custom_column/" + settingId)
        .then(() => {
            dispatch(removeColumnSettings(settingId));

            dispatch(requestComplete("Column settings deleted successfully"));
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Column settings delete failed";

            dispatch(requestFail(message));
        });
};

export const editColumnSetting = (setting) => (dispatch) => {
    dispatch(requestStart());

    return API.post("/edit_custom_column", setting)
        .then((res) => {
            const { data } = res.data;

            dispatch(updateColumnSettings(data));

            dispatch(requestComplete("Column settings updated successfully"));

            return data;
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Column settings edit failed";

            dispatch(requestFail(message));

            throw message;
        });
};

export const makeColumnSettingDefault = (settingId) => (dispatch) => {
    if (typeof settingId !== "number" || isNaN(settingId)) {
        return new Promise.reject("Invalid settings ID");
    }

    dispatch(requestStart());

    return API.post("/default_custom_column/" + settingId)
        .then(() => {
            dispatch(
                requestComplete("Column settings made default successfully")
            );
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ??
                "Column settings make default failed";

            dispatch(requestFail(message));
        });
};

export const addColumnSetting = (setting) => (dispatch) => {
    dispatch(requestStart());

    return API.post("/custom_column", setting)
        .then((res) => {
            const { data } = res.data;

            dispatch(insertColumnSetting(data));

            dispatch(requestComplete("Column settings added successfully"));

            return data;
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Adding column settings failed";

            dispatch(requestFail(message));

            throw message;
        });
};

export const createApprovalExcel = (data) => (dispatch) => {
    dispatch(requestStart());

    return API.post("/request-for-approval", data)
        .then((res) => {
            const {
                data: [{ id: request_id }],
            } = res.data;

            dispatch(requestComplete());

            return request_id;
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Approval request send failed";

            dispatch(requestFail(message));

            throw message;
        });
};

export const approvalMailRequest = (data) => (dispatch) => {
    dispatch(requestStart());

    return API.post("/mail-request", data)
        .then(() => {
            dispatch(
                requestComplete({
                    successMessage: "Approval request sent successfully",
                    success: true,
                })
            );
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Approval request send failed";

            dispatch(requestFail(message));

            throw message;
        });
};

export const getApproverEmails = () => (dispatch) => {
    dispatch(requestStart());

    return API.get("/user-approver-email")
        .then((res) => {
            const { data } = res.data;

            dispatch(
                setApproverAndCCEmails({
                    approverEmails: data[0][0].approva_email,
                    ccEmails: data[1][0].user_email,
                })
            );

            dispatch(requestComplete());
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ??
                "Approver email list fetch failed";

            dispatch(requestFail(message));
        });
};

export const changeToAMFFunc = (data) =>
    API({ url: "/change_to_amf", method: "post", data }).catch((err) => {
        throw err?.response?.data?.message ?? "Changing to AMF failed";
    });

export const editSpecialAttribute = (data) =>
    API.post("/edit-special-attributes", data);

export const getTopFiveMaterials = (data) => (dispatch) => {
    // dispatch(requestStart());

    return API({ url: "/get_top_five_materials", method: "post", data })
        .then((res) => {
            const { data } = res.data;
            return data;
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Interval Server Error";

            dispatch(toastError(message));
        });
    // .finally(() => dispatch(requestComplete()));
};

export const updateSimilarPFSMaterials = (data) => (dispatch) => {
    // dispatch(requestStart());

    return API({ url: "/update_similar_pfs_material", method: "put", data })
        .then((res) => {
            const { data } = res.data;
            return data;
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Interval Server Error";

            dispatch(toastError(message));
        });
};
